/* src/Components/AppContainer/AppContainer.css */
.app-container {
  background-color: #eceff5;
  background-image: linear-gradient(228deg, #caddde 3.75%, #f6fcfb 28.32%);
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  flex: 1;
}

/* Responsive styles */
@media (max-width: 768px) {
  .app-container {
    padding: 20px; /* Reduce padding on smaller screens */
  }
}

@media (max-width: 480px) {
  .app-container {
    padding: 10px; /* Further reduce padding on very small screens */
    flex-direction: column; /* Ensure column layout on small screens */
  }
}
