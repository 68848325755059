.TopBar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 40px;
}

.TopBarContainer {
  display: flex;
}

.TopBarText {
  cursor: pointer;
  color: #000000;
  font-family: Inter;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.02em;
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: bold;
}

.BetaText {
  color: #696969;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 29px; /* 241.667% */
  letter-spacing: 0.2px;
}

.MedRPT {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.responsive-svg {
  width: 100%;
  height: auto;
  max-width: 184px;
}

@media (max-width: 768px) {
  .TopBar {
    flex-direction: column;
    align-items: center;
  }

  .TopBarText {
    font-size: 14px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .BetaText {
    font-size: 0.9rem;
    line-height: 24px;
  }

  .responsive-svg {
    max-width: 150px;
  }
}
