/* Fullscreen overlay */
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

/* Card container */
.EmailCard {
  background-color: #fff;
  border-radius: 16px;
  padding: 32px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  position: relative;
  text-align: center;
}

/* Header */
.EmailText {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

/* Input fields */
.EmailBox {
  width: 100%;
  padding: 12px 16px;
  margin: 16px 0;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  font-size: 14px;
  background-color: #fff;
  color: #333;
  box-sizing: border-box;
  transition: border-color 0.2s ease;
}

.EmailBox:focus {
  outline: none;
  border-color: #0056b3;
  box-shadow: 0 0 0 2px rgba(0, 86, 179, 0.1);
}

/* Button container */
.ButtonContainer {
  display: flex;
  gap: 12px;
  margin-top: 20px;
}

/* Base button styles */
.Button {
  flex: 1;
  padding: 12px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
}

/* Submit button specific styles */
.SubmitButton {
  background-color: #0056b3;
  color: white;
}

.SubmitButton:hover {
  background-color: #004080;
}

/* Cancel button specific styles */
.CancelButton {
  background-color: #fff;
  color: #0056b3;
  border: 1px solid #0056b3;
}

.CancelButton:hover {
  background-color: #f5f5f5;
}
