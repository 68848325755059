.SearchResults {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  margin: 20px;
}

.loading-status {
  margin-top: 16px;
  color: #666;
  font-size: 1rem;
}

.article-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  height: 100%;
  box-sizing: border-box; /* Ensures padding doesn't affect dimensions */
}
