.RecentCard {
  border-radius: 32px;
  border: 2px solid #e1eeec;
  background: #e8f1f3;
  box-shadow: 1px 1px 11px 0px rgba(0, 0, 0, 0.05);
  margin-top: 40px;
  width: 100%;
  max-width: 1107px;
  padding: 20px;
  .Header {
    color: #000;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px; /* 212.5% */
  }
  .Content {
    display: flex;
    flex-wrap: wrap; /* Allows pills to wrap onto the next line */
    padding: 25px 25px 25px 25px;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .RecentCard {
    padding: 15px; /* Further reduce padding on smaller screens */
  }
  .Header {
    font-size: 14px; /* Adjust font size for smaller screens */
    line-height: 28px; /* Adjust line height */
  }
  .Content {
    padding: 15px; /* Adjust padding */
  }
}

.Pil {
  font-family: "Archivo", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  color: #0158db;
  border: 1px solid #e4e7ea;
  background: #ffffff;
  padding: 2px 6px;
  gap: 6px;
  border-radius: 100px;
  margin: 3px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .Pil {
    font-size: 0.85rem; /* Adjust font size for smaller screens */
  }
}

.Pil:hover {
  background: #0158db;
  color: #ffffff;
}
