.QueryTermCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 1px 1px 11px 0px #0000000d;
  background: #f8f9fc;
  padding: 20px;
  border-radius: 16px;
  margin-top: 20px;
  margin-right: 10px;
}

.QueryTermCard .Body {
  padding-left: 10px;
}

.FilterQueryImage {
  margin-right: 10px;
}

.QueryTermCard .Header {
  color: #000;
  font-family: Inter;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 141.2%;
  padding-top: 50px;
}

.QueryTermCard hr {
  width: 100%;
  border: none;
  height: 1px;
  background-color: #b9bcc1;
  margin: 20px 0;
}
.QueryTermCard .QueryTermContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.TextAreaContainer .QueryTextarea {
  width: 100%;
  min-height: 200px;
  max-height: 400px;
  resize: vertical;
  padding: 8px;
  font-family: Inter;
  color: #045484;
  overflow: auto;
  font-size: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.QueryTermCard .TextAreaContainer {
  margin-top: 50px;
}

.QueryTermCard .Chart {
  margin-right: 10px;
}

@media (min-width: 992px) {
  .QueryTermCard {
    width: 50%;
  }
}

@media (max-width: 992px) {
  .QueryTermCard {
    width: 50%;
  }
}
