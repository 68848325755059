.FilterCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 1px 1px 11px 0px #0000000d;
  background: #f7f8fc;
  width: 40%;
  border-radius: 16px;
  padding: 45px;
}

.FilterCard .Body {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  width: 90%;
}

.ToggleModalCloseButton {
  background: none;
  border: none;
  text-align: right;
  cursor: pointer;
  font-size: 1.2rem;
  transition: opacity 0.2s ease-in-out;
  margin-bottom: 20px;
}
.FilterCard .AgeAbstractContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.FilterCard .AgeGenderFilterExplaination {
  color: rgba(0, 0, 0, 0.6);
  font-size: 15px;
  margin-bottom: 30px;
}

.FilterCard .Age {
  display: flex;
  flex-direction: column;
}

.YearRange h4 {
  margin-bottom: 15px;
}
.Age select {
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f5f5f5;
  padding: 8px;
  margin-bottom: 4px;
  margin-top: 0;
}

.AgeAbstractContainer,
.YearRange {
  margin-bottom: 30px;
}
.Age option {
  background-color: white;
}

.Gender .RadioButtons label {
  margin-right: 16px;
}

.YearRange {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  width: 90%;
}

.YearRange .Inputs {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.YearRange h4 {
  color: rgba(0, 0, 0, 0.6);
}

.ClearButton,
.ApplyButton {
  padding: 10px 20px;
  border-radius: 14px;

  cursor: pointer;
  transition: background 0.3s ease;
  text-transform: uppercase;

  @media (max-width: 768px) {
    padding: 8px 16px;
  }
}

.ApplyButton {
  background: #004c84;
  box-shadow: 1px 1px 11px 0px rgba(0, 0, 0, 0.05);
  color: white;
}

.ApplyButton:hover {
  background: #003366;
}

@media (min-width: 992px) {
  .FilterCard {
    width: 40%;
  }
}

@media (max-width: 992px) {
  .FilterCard {
    width: 80%;
  }
}
