.OrderCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1107px;
  padding: 25px 28px 25px 24px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 32px;
  border: 2px solid #e1eeec;
  background: #edf4f5;
  box-shadow: 1px 1px 11px 0px rgba(0, 0, 0, 0.05);
  margin-top: 30px;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .OrderCard {
    padding: 20px;
    border-radius: 16px;
  }
}

/* Media query for very small screens */
@media (max-width: 480px) {
  .OrderCard {
    padding: 15px;
    margin-top: 20px;
  }
}
