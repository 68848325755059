.AdvancedCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px solid #e1eeec;
  background: #ebf1f3;
  box-shadow: 1px 1px 11px 0px rgba(0, 0, 0, 0.05);
  width: 40%;
  border-radius: 16px;
  padding: 45px;
  max-height: calc(100vh - 80px);
  margin: 0 auto;
  overflow-y: auto;
}

.AdvancedCard .Body {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  width: 90%;
}

.AdvancedCard .AdvancedCardFilters {
  display: flex;
  flex-direction: row;
}
.AdvancedCard form {
  display: flex;
  flex-direction: column;
}
.AdvancedToggleModalCloseButton {
  background: none;
  border: none;
  text-align: right;
  cursor: pointer;
  font-size: 1.2rem;
  transition: opacity 0.2s ease-in-out;
  margin-bottom: 20px;
  align-self: flex-end;
}

.AdvancedApplyButton {
  z-index: 1;
}

.AdvancedApplyButton:hover {
  background: #003366;
}

.AdvancedApplyButton {
  display: block;
  width: fit-content;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 14px;
  background: #004c84;
  box-shadow: 1px 1px 11px 0px rgba(0, 0, 0, 0.05);
  color: white;
  text-align: center;
  cursor: pointer;
  transition: background 0.3s ease;
  text-transform: uppercase;
  align-self: center;
}

.AdvancedCard .PicoContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
@media (min-width: 992px) {
  .AdvancedCard {
    width: 50%;
  }
}

@media (max-width: 992px) {
  .AdvancedCard {
    width: 80%;
  }
}
