body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.root {
  display: flex;
}

.footer {
  color: #000000;
  font-family: Inter;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.02em;
  font-weight: bold;
  padding: 20px;
  text-align: center;
}
