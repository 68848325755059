.PICOCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-shadow: 1px 1px 11px 0px #0000000d;
  background: #f8f9fc;
  padding: 20px;
  border-radius: 16px;
  margin-top: 20px;
  margin-right: 10px;
}

.PICOCard .Body {
  padding-left: 10px;
}

.PICOCard .Header {
  color: #000;
  font-family: Inter;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 141.2%;
  padding-top: 50px;
}

.PICOCard .ItemContainer {
  display: flex;
  flex-direction: column;
  color: #045484;
  margin-top: 20px;
}

.PICOCard .Title {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.PICOCard .Text {
  color: #045484;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.PICOCard hr {
  width: 100%;
  border: none;
  height: 1px;
  background-color: #b9bcc1;
  margin: 20px 0;
}

.PICOCard .ItemContainer input {
  width: 100%;
  padding: 10px;
  margin-top: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: Inter;
  color: #045484;
}

.PICOCard .ItemContainer input:focus {
  outline: none;
  border-color: #045484;
  box-shadow: 0 0 5px rgba(4, 84, 132, 0.2);
}

.PICOCard .ChartContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.PICOCard .Chart {
  margin-right: 10px;
}

@media (min-width: 992px) {
  .PICOCard {
    width: 50%;
  }
}

@media (max-width: 992px) {
  .PICOCard {
    width: 50%;
  }
}
