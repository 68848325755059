@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");

.container {
  position: relative;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
}

.orderCard {
  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
  padding: 40px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

@media (max-width: 991px) {
  .orderCard {
    max-width: 100%;
    padding: 0 20px;
  }
}

.innerCard {
  border-radius: 16px;
  background-color: #fff;
  box-shadow: none;
  padding: 10px;
  border: 1px solid #edf0f2;
}

@media (max-width: 991px) {
  .innerCard {
    max-width: 100%;
    padding: 0 20px;
  }
}

.contentWrapper {
  gap: 20px;
  display: flex;
}

@media (max-width: 991px) {
  .contentWrapper {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
}

.mainColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 68%;
  margin-left: 0;
}

@media (max-width: 991px) {
  .mainColumn {
    width: 100%;
  }
}

.trustScoreContent {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
}

@media (max-width: 991px) {
  .trustScoreContent {
    max-width: 100%;
    margin-top: 40px;
  }
}

.trustScoreTitle {
  color: #19252f;
  font: 700 24px/1.2 "Inter", sans-serif;
  margin-bottom: 16px;
}

.trustScoreDescription {
  color: #4a5568;
  font: 400 14px/1.6 "Inter", sans-serif;
  margin-bottom: 32px;
}

@media (max-width: 991px) {
  .trustScoreDescription {
    max-width: 100%;
  }
}

.trustScoreImage {
  aspect-ratio: 500;
  object-fit: contain;
  object-position: center;
  width: 433px;
  margin-top: 78px;
  max-width: 100%;
}

@media (max-width: 991px) {
  .trustScoreImage {
    margin-top: 40px;
  }
}

.keyRangeTitle {
  color: #19252f;
  font: 700 16px/2 "Inter", sans-serif;
}

@media (max-width: 991px) {
  .keyRangeTitle {
    margin-top: 40px;
  }
}

.keyRangeWrapper {
  display: flex;
  gap: 48px;
  margin-top: 16px;
}

.rangeColumn,
.reviewColumn {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.rangeIndicators,
.reviewIndicators {
  display: none;
}

.rangeLabels,
.reviewLabels {
  color: #4a5568;
  font: 400 14px/1.6 "Inter", sans-serif;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.rangeLabels div,
.reviewLabels div {
  display: flex;
  align-items: center;
  gap: 8px;
}

.indicator {
  display: inline-block;
  width: 16px;
  height: 8px;
  border-radius: 4px;
  margin-right: 8px;
}

.scoreColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 32%;
  margin-left: 20px;
  align-items: center;
}

@media (max-width: 991px) {
  .scoreColumn {
    width: 100%;
  }
}

.scoreWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 40px;
}

.scoreCircle {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.scoreInner {
  position: relative;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scoreValue {
  font-size: 48px;
  font-weight: 700;
  color: #19252f;
  letter-spacing: normal;
}

.scorePercentage {
  font-size: 24px;
  margin-top: 0;
  margin-left: 4px;
}

.scoreDescription {
  text-align: center;
  color: #4a5568;
  font: 400 14px/1.6 "Inter", sans-serif;
  margin-top: 16px;
  width: 100%;
}

@media (max-width: 991px) {
  .scoreDescription {
    margin: 0 10px;
  }
}

.boldText {
  font-weight: 600;
  color: #19252f;
  display: inline-block;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
