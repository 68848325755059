.PilGo {
  font-family: "Archivo", sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  border: 1px solid #e4e7ea;
  padding: 5px 10px;
  gap: 6px;
  border-radius: 100px;
  margin: 5px;
  cursor: pointer;
  background: #0158db;
  color: #ffffff;
}

.PilGo:hover {
  color: #0158db;
  background: #ffffff;
}

.Home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
