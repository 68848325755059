:root {
  --color-background: #f8f9fc;
  --color-shadow: rgba(0, 0, 0, 0.05);
  --color-title: #2f2f2f;
  --color-subheader: #045484;
  --color-headervalue: #000;
  --color-insight-bg: #f8fafa;
  --color-badge-highly-cited: #399d39;
  --color-badge-highly-trusted: #399d39;
  --color-badge-moderately-cited: #e29b10;
  --color-badge-moderately-trusted: #e29b10;
  --color-badge-sparsely-cited: #ff0000;
  --color-badge-sparsely-trusted: #ff0000;
  --color-badge-peer-reviewed: #0480c6;
  --color-badge-not-reviewed: #acaaa3;
}

.ArticleCard {
  position: relative;
  display: flex;
  flex-direction: column;
   {
     {
      /* align-items: center; */
    }
  }
  justify-content: center;
  box-shadow: 1px 1px 11px 0px var(--color-shadow);
  background: var(--color-background);
  width: 90%;
  padding: 20px;
  border-radius: 16px;
  margin-top: 20px;
  cursor: pointer;
}

.ArticleCard.collapsed {
  padding: 20px;
}

.ArticleCard .CollapseIcon {
  position: absolute;
  top: 10px;
  right: 30px;
  cursor: pointer;
  font-size: 18px;
  user-select: none;
}

.ArticleCard .Body {
  padding: 20px 60px;
}

.ArticleCard .Title {
  color: var(--color-title);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 10px;
  width: 100%;
  text-align: left; /* Ensure text is aligned to the left */
}

.ArticleCard .Subheader {
  color: var(--color-subheader);
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.225px;
}

.ArticleCard .Headervalue {
  color: #4a5568;
  overflow: hidden;
  color: var(--color-headervalue);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.225px;
  margin-left: 5px;
}

.ArticleCard .Insight {
  border-radius: 14px;
  border: 1px solid #ebebeb;
  background: var(--color-insight-bg);
  box-shadow: 1px 1px 11px 0px var(--color-shadow);
  padding: 30px;
  margin-top: 10px;
}

.ArticleCard .InsightHeader {
  color: var(--color-subheader);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  margin-bottom: 10px;
}

.ArticleCard .InsightValue {
  color: #2c2c2c;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  white-space: pre-wrap;
}

.ArticleCard .Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2.5px;
}

.ArticleCard .Badge {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 12px;
  margin-right: 5px;
  color: #fff;
}

.ArticleCard .Badge.HighlyCited {
  background-color: var(--color-badge-highly-cited);
}

.ArticleCard .Badge.ModeratelyCited {
  background-color: var(--color-badge-moderately-cited);
}

.ArticleCard .Badge.SparselyCited {
  background-color: var(--color-badge-sparsely-cited);
}

.ArticleCard .Badge.PeerReviewed {
  background-color: var(--color-badge-peer-reviewed);
}

.ArticleCard .Badge.NotReviewed {
  background-color: var(--color-badge-not-reviewed);
}

.Badge.TrustScore.High {
  background-color: var(--color-badge-highly-trusted);
  color: white;
}

.Badge.TrustScore.Moderate {
  background-color: var(--color-badge-moderately-trusted);
  color: black;
}

.Badge.TrustScore.Fair {
  background-color: var(--color-badge-sparsely-trusted);
  color: white;
}

.Badge a {
  color: white;
  text-decoration: none; /* Optional: to remove underline */
}

@media (max-width: 768px) {
  .ArticleCard {
    padding: 15px;
  }

  .ArticleCard .Title {
    line-height: 26px;
  }

  .ArticleCard .Subheader,
  .ArticleCard .Headervalue {
    font-size: 13px;
    line-height: 20px;
  }

  .ArticleCard .Insight {
    padding: 15px;
  }

  .ArticleCard .InsightHeader {
    font-size: 15px;
    line-height: 18px;
  }

  .ArticleCard .InsightValue {
    font-size: 13px;
    line-height: 20px;
  }
}

@media (max-width: 480px) {
  .ArticleCard {
    padding: 10px;
  }

  .ArticleCard .Body {
    padding: 0;
  }
}

.ViewFullStudyButton {
  font-family: Inter;
  font-size: 14px;
  display: block; /* Ensures the button is on its own row */
  width: fit-content; /* Adjusts the width to fit the content */
  margin: 20px 0; /* Aligns the button to the left */
  padding: 10px 20px; /* Adds padding for better clickability */
  border-radius: 14px;
  background: #004c84;
  box-shadow: 1px 1px 11px 0px rgba(0, 0, 0, 0.05);
  color: white;
  text-align: center;
  cursor: pointer; /* Changes the cursor to a pointer on hover */
  transition: background 0.3s ease; /* Smooth transition for hover effect */
  text-transform: uppercase;

  @media (max-width: 768px) {
    padding: 8px 16px; /* Adjust padding for smaller screens */
  }
}

.ViewFullStudyButton:hover {
  background: #003366;
}

.ViewFullStudyButton a {
  color: white; /* Ensure link text is white */
  text-decoration: none; /* Remove underline */
}
