.Card {
  position: relative; /* Ensure positioning context for absolute elements */
  overflow: visible; /* Temporarily allow overflow to check visibility */
  border-radius: 32px;
  border: 2px solid #c3dede;
  background: #dfeaed;
  box-shadow: 0px 1px 50px 0px rgba(0, 0, 0, 0.08);
  width: 100%;
  max-width: 1107px;
  flex-shrink: 0;
  padding: 2%;
  z-index: 1;

  /* Add the SVG as a background */
  &::after {
    content: ""; /* Required for pseudo-element */
    position: absolute; /* Position it relative to the .Card */
    top: 0; /* Align to the top */
    right: 0; /* Align to the right */
    width: 316px; /* Set width of the SVG */
    height: 222px; /* Set height of the SVG */
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="316" height="222" viewBox="0 0 316 222" fill="none"><path d="M224.648 89.5755L254.33 106.598L173.791 155.641V200.406L315.797 119.388V93.8081L156.824 0L0 95.9705V142.852L157.876 49.0434L188.609 66.112L0 174.826V221.753L224.648 89.5755Z" fill="%23D1DDDC"/></svg>')
      no-repeat; /* Use SVG as background */
    background-size: contain; /* Ensure the SVG scales properly */
    z-index: -1; /* Send it further back */

    @media (max-width: 768px) {
      width: 200px; /* Reduce the width of the SVG for smaller screens */
      top: 10px; /* Adjust position if necessary */
      right: 10px; /* Adjust position if necessary */
    }
  }

  .Header {
    color: #000;
    text-align: center;
    font-family: "DM Sans";
    font-size: 65px;
    font-style: normal;
    font-weight: 700;
    line-height: 109%; /* 70.85px */
    letter-spacing: -1.95px;
    margin-bottom: 40px;

    @media (max-width: 768px) {
      font-size: 40px; /* Reduce font size for smaller screens */
      margin-bottom: 20px; /* Adjust margin for smaller screens */
    }
  }

  .GreenText {
    color: #038026;
  }

  .InputQuestionDiv {
    background: #ffffff;
    width: 90%;
    height: auto;
    border-radius: 16px;
    border: 0.25px solid #0b6dff;
    box-shadow: 1px 1px 11px 0px #0000000d, -1px -1px 11px 0px #0000000d;
    display: flex;
    align-items: center;
    padding-left: 5%;

    @media (max-width: 768px) {
      padding-left: 2%; /* Adjust padding for smaller screens */
    }
  }

  .InputQuestion {
    background: #ffffff;
    font-family: "Inter", sans-serif;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    text-align: left;
    color: #2f2f2f;
    border: none;
    width: 90%;
    height: 100%;
    padding: 5px;
    box-sizing: border-box;
    resize: none;
    outline: none !important;
  }

  .SearchContainer {
    position: relative;
    display: flex;
    align-items: center;
  }

  .Search {
    position: absolute;
    width: 18.6px;
    height: 19px;
    right: 31px;
    bottom: 31px;
  }

  .CardButtons {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    align-items: center;
    padding-right: 50px;
  }
  .Button {
    display: block; /* Ensures the button is on its own row */
    width: fit-content; /*Adjusts the width to fit the content*/
    margin: 20px auto; /* Centers the button horizontally and adds some margin */
    padding: 10px 20px; /* Adds padding for better clickability */
    border-radius: 14px;
    background: #004c84;
    box-shadow: 1px 1px 11px 0px rgba(0, 0, 0, 0.05);
    color: white;
    text-align: center;
    cursor: pointer; /* Changes the cursor to a pointer on hover */
    transition: background 0.3s ease; /* Smooth transition for hover effect */
    text-transform: uppercase;

    @media (max-width: 768px) {
      padding: 8px 16px; /* Adjust padding for smaller screens */
    }
  }

  .FilterButton {
    background: white;
    color: black;
  }

  .SubmitButton:hover {
    background: #003366; /* Darker shade for hover effect */
  }

  .FilterCardOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  @media (max-width: 768px) {
    width: 100%; /* Make the card take full width on smaller screens */
    padding: 10px; /* Reduce padding for smaller screens */
  }
}

.Header,
.GreenText,
.InputQuestionDiv,
.InputQuestion,
.SearchContainer,
.Search,
.Button {
  z-index: 1; /* Ensure these elements appear above the pseudo-element */
}
