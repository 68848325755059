.FoundCard {
  padding: 20px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-self: center;
  justify-content: center;
  box-shadow: 1px 1px 11px 0px #0000000D;
  background: #F8F9FC;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  max-width: 1107px;
}

.FoundCard .text {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 141.2%;
  padding-left: 30px;
}

.FoundCard .qualified {
  color: #045484;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 141.2%;
}

.FoundCard .analyzed {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 141.2%;
}