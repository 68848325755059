.AboutCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: 1px 1px 11px 0px #0000000d;
  background: #f8f9fc;
  padding: 60px;
  border-radius: 16px;
  margin-top: 20px;
}

.AboutHeader {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #2f2f2f;
  font-family: Inter;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
}

.AboutText {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #000000;
}
