.ProgressBar {
  width: 100%;
  max-width: 572px;
}

.ProgressBar .progress {
  border-radius: 10px;
  background: #e4e4e4;
}

.ProgressBar .progressBar {
  border-radius: 10px;
  background: #1bb722;
  height: 13px;
}

@media (max-width: 768px) {
  .ProgressBar {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .ProgressBar .progressBar {
    height: 10px;
  }
}
